
import { defineComponent, ref, watch, onMounted } from "vue";
import reconnectingWebsocket from "reconnecting-websocket";
import { qrimageLogo, getEffectGiftList } from "@/api/api"; // 接口
import { useRoute, useRouter } from "vue-router";
import { initDB } from "@/utils";
// import SVGA from "svgaplayerweb";
import { Parser, DB } from 'svga'
export default defineComponent({
  name: "Entrance",
  setup() {
    const params = useQueryParams(); // 浏览器参数
    const { query } = useRoute();
    const ws: any = ref();
    const hallcode = ref("hdfkdfhd");
    // const uuid = ref('qr_95679693B9684486B8AC774EB3532');
    const uuid = ref();
    const qrUrl = ref();
    const router = useRouter();
    const db = new DB()

    onMounted(() => {
      _qrimageLogo();
      _getEffectGiftList();
    });

    const _qrimageLogo = () => {
      qrimageLogo({ uuid: hallcode.value }).then((res: any) => {
        const { code, result } = res.data;
        if (code === 101) {
          uuid.value = result.uuid
          qrUrl.value = result.url;
        }
        _initWebSocket();
      });
    };

    const _getEffectGiftList = async () => {
      getEffectGiftList({}).then(async (res: any) => {
        const { code, result } = res.data;
        if (code === 101) {
          result.forEach(async (item: any) => {
            // 通过giftCode去获取
            // save(item);
            save2(item);
          });
        }
      });
    };

    const save2 = async (item: any) => {
      let svga = await db.find(item.giftCode)
      if (!svga) {
        let svgaUrl = `https://fdshare.oss-cn-shenzhen.aliyuncs.com/live/specialEffects/svga%E5%8C%85/${item.giftCode}_web.svga`;
        svgaUrl = await isExistFile(svgaUrl) ? svgaUrl : item.giftGifImg;
        const parser = new Parser({ isDisableImageBitmapShim: true })
        svga = await parser.load(svgaUrl)
        await db.insert(item.giftCode, svga)
      }
    };

    // const save = async (item: any) => {
    //   const initDBRes = await initDB();
    //   const getByGiftCodeRequest = initDBRes.objectStore.get(item.giftCode);
    //   initDBRes.objectStore.get("url").onsuccess = () => {
    //     if (!getByGiftCodeRequest.result) {
    //       const parser = new SVGA.Parser();
    //       const svgaUrl = `https://fdshare.oss-cn-shenzhen.aliyuncs.com/live/specialEffects/svga%E5%8C%85/${item.giftCode}_web.svga`;
    //       parser.load(
    //         isExistFile(svgaUrl) ? svgaUrl : item.giftGifImg,
    //         async (videoItem: any) => {
    //           const initDBRes = await initDB();
    //           // 新增
    //           initDBRes.objectStore.add({
    //             url: item.giftCode,
    //             result: videoItem
    //           });
    //         }
    //       );
    //     }
    //   };
    // };

    /**
     * 判断服务的文件是否存在
     * @param url 文件地址
     * @returns {Boolean}
     */
    const isExistFile = (url: string) => {
      if (url == null) {
        return false;
      }
      let xmlhttp;
      if (window.XMLHttpRequest) {
        xmlhttp = new XMLHttpRequest();
      } else {
        xmlhttp = new ActiveXObject("Microsoft.XMLHTTP");
      }
      xmlhttp.open("GET", url, false);
      xmlhttp.send();
      if (xmlhttp.readyState === 4) {
        if (xmlhttp.status === 200) return true;
        //url存在
        else if (xmlhttp.status === 404) return false;
        //url不存在
        else return false; //其他状态
      }
    };

    const _initWebSocket = () => {
      ws.value = new reconnectingWebsocket(`${process.env.VUE_APP_Url_WebSocket}?bc=${hallcode.value}&ut=${uuid.value}`); // 生产
      ws.value.onopen = _wsOnOpen;
      ws.value.onmessage = _wsOnMessage;
      ws.value.onerror = _wsOnError;
      ws.value.onclose = _wsOnClose;
    };

    const _wsOnOpen = () => {
      console.log("ws建立连接");
    };

    const _wsOnMessage = (msg: any) => {
      // console.log("ws新消息", JSON.parse(msg.data));
      console.log("ws新消息", msg.data);
      const {
        sendType,
        messageContent,
        sendUserName,
        sendUserHeadImgUrl
      } = JSON.parse(msg.data);
      const messageStr =
        sendType === "1" || sendType === "20" || sendType === "25"
          ? messageContent.messageStr
          : JSON.parse(messageContent.messageStr);
      if (sendType === "55") {
        router.push({
          path: `/`,
          query: {
            hallCode: messageStr.hallCode
          }
        });
      }
    };

    const _wsOnError = (e: any) => {
      console.log("ws发生错误", e);
    };

    const _wsOnClose = () => {
      console.log("ws断开连接");
    };

    const routerNavigateTo = (url: string, param: any) => {
      router.push({
        path: url,
        query: param
      });
    };

    return {
      routerNavigateTo,
      hallcode,
      qrUrl
    };
  }
});

/**
 * 获取浏览器参数
 */
function useQueryParams() {
  const search = window.location.href
    .replace(/^\s+/, "")
    .replace(/\s+$/, "")
    .match(/([^?#]*)(#.*)?$/); // 提取location.search中'?'后面的部分
  if (!search) {
    return {};
  }
  const searchStr = search[1];
  const searchHash = searchStr.split("&");

  const ret: any = {};
  for (let i = 0, len = searchHash.length; i < len; i++) {
    // 这里可以调用each方法
    let pair: any = searchHash[i];
    if ((pair = pair.split("="))[0]) {
      const key = decodeURIComponent(pair.shift());
      let value = pair.length > 1 ? pair.join("=") : pair[0];

      if (value != undefined) {
        value = decodeURIComponent(value);
      }
      if (key in ret) {
        if (ret[key].constructor != Array) {
          ret[key] = [ret[key]];
        }
        ret[key].push(value);
      } else {
        ret[key] = value;
      }
    }
  }
  return ret;
}
