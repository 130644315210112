import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2645585c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "lottery-panel" }
const _hoisted_2 = { class: "lottery-scroll lottery-scroll3" }
const _hoisted_3 = { class: "lottery-item" }
const _hoisted_4 = { class: "cubebox" }
const _hoisted_5 = { class: "cube cubeAni1" }
const _hoisted_6 = ["src"]
const _hoisted_7 = ["src"]
const _hoisted_8 = ["src"]
const _hoisted_9 = ["src"]
const _hoisted_10 = ["src"]
const _hoisted_11 = ["src"]
const _hoisted_12 = { class: "lottery-scroll" }
const _hoisted_13 = { class: "lottery-item" }
const _hoisted_14 = { class: "cubebox" }
const _hoisted_15 = { class: "cube cubeAni2" }
const _hoisted_16 = ["src"]
const _hoisted_17 = ["src"]
const _hoisted_18 = ["src"]
const _hoisted_19 = ["src"]
const _hoisted_20 = ["src"]
const _hoisted_21 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Prize = _resolveComponent("Prize")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: _normalizeClass(["lottery-list", `lottery${item.index}`]),
          key: index
        }, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("img", {
                  class: "first",
                  src: item.imageUrl
                }, null, 8, _hoisted_6),
                _createElementVNode("img", {
                  class: "second",
                  src: item.imageUrl
                }, null, 8, _hoisted_7),
                _createElementVNode("img", {
                  class: "third",
                  src: item.imageUrl
                }, null, 8, _hoisted_8),
                _createElementVNode("img", {
                  class: "fourth",
                  src: item.imageUrl
                }, null, 8, _hoisted_9),
                _createElementVNode("img", {
                  class: "fifth",
                  src: item.imageUrl
                }, null, 8, _hoisted_10),
                _createElementVNode("img", {
                  class: "sixth",
                  src: item.imageUrl
                }, null, 8, _hoisted_11)
              ])
            ]),
            _createElementVNode("p", null, _toDisplayString(item.nickName || ""), 1)
          ])
        ], 2))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_12, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.winList, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: _normalizeClass(["lottery-list", `lotteryWin${item.index}`]),
          key: index
        }, [
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("div", _hoisted_14, [
              _createElementVNode("div", _hoisted_15, [
                _createElementVNode("img", {
                  class: "first",
                  src: 
                  item.avatarUrl
                    ? item.avatarUrl
                    : 'https://fdshare.oss-cn-shenzhen.aliyuncs.com/meet/invitation/assets/lotteryDefulet.png'
                
                }, null, 8, _hoisted_16),
                _createElementVNode("img", {
                  class: "second",
                  src: 
                  item.avatarUrl
                    ? item.avatarUrl
                    : 'https://fdshare.oss-cn-shenzhen.aliyuncs.com/meet/invitation/assets/lotteryDefulet.png'
                
                }, null, 8, _hoisted_17),
                _createElementVNode("img", {
                  class: "third",
                  src: 
                  item.avatarUrl
                    ? item.avatarUrl
                    : 'https://fdshare.oss-cn-shenzhen.aliyuncs.com/meet/invitation/assets/lotteryDefulet.png'
                
                }, null, 8, _hoisted_18),
                _createElementVNode("img", {
                  class: "fourth",
                  src: 
                  item.avatarUrl
                    ? item.avatarUrl
                    : 'https://fdshare.oss-cn-shenzhen.aliyuncs.com/meet/invitation/assets/lotteryDefulet.png'
                
                }, null, 8, _hoisted_19),
                _createElementVNode("img", {
                  class: "fifth",
                  src: 
                  item.avatarUrl
                    ? item.avatarUrl
                    : 'https://fdshare.oss-cn-shenzhen.aliyuncs.com/meet/invitation/assets/lotteryDefulet.png'
                
                }, null, 8, _hoisted_20),
                _createElementVNode("img", {
                  class: "sixth",
                  src: 
                  item.avatarUrl
                    ? item.avatarUrl
                    : 'https://fdshare.oss-cn-shenzhen.aliyuncs.com/meet/invitation/assets/lotteryDefulet.png'
                
                }, null, 8, _hoisted_21)
              ])
            ]),
            _createElementVNode("p", null, _toDisplayString(item.trueName), 1)
          ])
        ], 2))
      }), 128))
    ]),
    _withDirectives(_createElementVNode("div", null, [
      _createVNode(_component_Prize, {
        ref: "prize",
        prizeType: _ctx.prizeType,
        resultList: _ctx.resultList,
        "onUpdate:resultList": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.resultList) = $event)),
        resultList2: _ctx.resultList2,
        "onUpdate:resultList2": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.resultList2) = $event)),
        prizeInfo: _ctx.prizeInfo,
        hallCode: _ctx.hallCode
      }, null, 8, ["prizeType", "resultList", "resultList2", "prizeInfo", "hallCode"])
    ], 512), [
      [_vShow, _ctx.prizeType != '-1']
    ])
  ]))
}