
import { defineComponent, ref, onMounted } from "vue";
export default defineComponent({
  name: "TcPlayer1",
  components: {},
   props: {
    palyerUrl: {
      type: String,
      default: ""
    }
  },  
  setup(props) {
    const list: any = ref([]);
    let player: any; 

    onMounted(() => {

      createPlayer()

    })

    const createPlayer = () => {
      /**
       * 视频类型播放优先级
       * mobile ：m3u8>mp4
       * PC ：RTMP>flv>m3u8>mp4
       */
      console.log(props.palyerUrl.replace('?','.flv?'))
      let options = {
        // webrtc: 'webrtc://yzb.91afd.com/live/101536e8105d49c5b80e21a408a9ec89?txSecret=55bc889c0e8601cf109338dd47adf100&txTime=61E19E00',
        // webrtc: props.palyerUrl,
        rtmp: props.palyerUrl.replace('webrtc','http').replace('?','.flv?'),
        // flv: props.palyerUrl.replace('webrtc','http').replace('?','.flv?') ,
        // m3u8: props.palyerUrl ,
        mp4: '' ,
        autoplay: true,
        live: true,
        width: document.body.clientWidth,
        height: document.body.clientHeight,
        volume: 0.6,
        flash: true,
        flashUrl: (function () {
          return '//imgcache.qq.com/open/qcloud/video/player/release/QCPlayer.swf'
        })(),
        x5_player: false,
        h5_flv: true,
        wording: {
          2032: '请求视频失败，请检查网络',
          2048: '请求m3u8文件失败，可能是网络错误或者跨域问题'
        },
        controls: "none",
        listener: function (msg:any) {
          console.log(msg)
        }
      };
      player =  new (window as any).TcPlayer('id_test_video', options);
    }

    const refresh = () => {
      player.load('')
    }

    return {
      list,
      refresh
    };
  }
});
