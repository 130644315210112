
import { defineComponent, ref, onMounted, watch, computed, inject } from "vue";
import { useStore } from "vuex";
import { initScreen } from "@/api/api"; // 接口

export default defineComponent({
  name: "Programme",
  components: {},
  props: {
    data: Array,
    hallCode: String
  },
  setup(props) {
    const store = useStore();
    // const hallCode = inject('hallCode', ref())

    const programmes: any = ref([]);

    watch(
      () => props.data,
      (val: any) => {
        programmes.value = [];
        for (let i = 0; i < val.length; i++) {
          programmes.value.push({
            text: val[i].name,
            like: val[i].supportNumber,
            dislike: val[i].unSupportNumber,
            status: val[i].status
          });
        }
      }
    );

    onMounted(() => {
      // programmes.value = [
      //   {
      //     text: '难忘今宵',
      //     like: '1000',
      //     dislike: '0'
      //   }
      // ]
      if (programmes.value.length <= 0) getProgrammeList();
    });

    const getProgrammeList = () => {
      initScreen({
        hallcode: props.hallCode
      }).then((res: any) => {
        const { code, result } = res.data;
        if (code === 101) {
          const arr = result.programmeVOList;
          programmes.value = [];
          for (let i = 0; i < arr.length; i++) {
            programmes.value.push({
              text: arr[i].name,
              like: arr[i].supportNumber,
              dislike: arr[i].unSupportNumber,
              status: arr[i].status
            });
          }
        }
      });
    };

    return {
      programmes,
      getProgrammeList
    };
  }
});
